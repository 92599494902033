import styles from './styles.module.css';
import {
	ContentfulIndustryPage,
	ContentfulCollapsiblePanelSection,
	Maybe,
	ContentfulIndustryStatisticsPanel,
	ContentfulVideoResource,
} from 'src/graphql-types';
import {
	PrivateCollapsiblePanelSection,
	defaultIndustryPageSections,
	formatDate,
	getHrImageUrl,
} from 'src/utils/common';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { MainImage } from 'src/components/mainImage';
import {
	SectionContainer,
	SectionContainerTitle,
	SectionContainerGrid,
} from 'src/components/sectionContainer';
import { LinkHelper } from 'src/utils/link-helper';
import { MainTabPanel, MainTabItem } from 'src/components/mainTabPanel';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import * as Icons from 'src/icons';
import { LinkWithDescriptionTemplate } from 'src/templates/linkWithDescription/linkWithDescriptionTemplate';
import { MultiCarousel } from 'src/components/multiCarousel';
import { ReadMore } from 'src/components/readMore';
import { curatedTitle } from 'src/utils/curated-title';
import { CollapsiblePanelCollection } from 'src/templates/collapsiblePanel/collapsiblePanelCollection';

interface ModifiedContentfulIndustryPage
	extends Omit<ContentfulIndustryPage, 'overviewCollapsiblePanels'> {
	overviewCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
}

interface IndustryStatisticsPanelsProps {
	data: Maybe<ContentfulIndustryStatisticsPanel>[];
}

interface PageContext {
	pageContext: {
		data: ModifiedContentfulIndustryPage;
	};
}

export default class IndustryPage extends React.Component<PageContext> {
	render() {
		const { data } = this.props?.pageContext;

		const addLayout = (children: JSX.Element) => {
			return <DefaultLayoutTemplate>{children}</DefaultLayoutTemplate>;
		};

		if (!data) {
			console.error('No page data for this IndustryPage');
			return addLayout(
				<SectionContainer withBorderTop>
					<p className={styles.message}>This page is under maintenance. Please try again later</p>
				</SectionContainer>
			);
		}

		const {
			contentful_id,
			title,
			subtitle,
			titleBackgroundImage,
			overviewContent,
			overviewCollapsiblePanels,
			industryStatisticsPanels,
			industryStatisticsMoreInfoLinks,
			videoResources,
			industryResourcesLinks,
			industryLinksCollapsiblePanels,
			overviewHeading,
			industryStatisticsHeading,
			videosHeading,
			industryResourcesHeading,
			industryLinksHeading,
		} = data;

		const getVideoThumbnails = (videos: Maybe<ContentfulVideoResource>[]) => {
			return videos
				.filter((video) => video)
				.map((video) => {
					return (
						<ReadMore
							key={video?.contentful_id || ''}
							image={
								video?.thumbnailLink?.thumbnailImage?.fluid?.src
									? {
											src: video?.thumbnailLink?.thumbnailImage?.fluid?.src,
											style: { height: '300px', width: 'auto' },
									  }
									: undefined
							}
							title={{
								titleText: video?.thumbnailLink?.title || '',
							}}
							publishedDate={formatDate(video?.publicationDate)}
							linkSection={{
								linkTo: LinkHelper.linkTo(video),
								withBorderTop: true,
								readMoreText: 'watch video',
							}}
						>
							{video?.thumbnailLink?.description}
						</ReadMore>
					);
				});
		};

		const IndustryStatisticsPanels = (props: IndustryStatisticsPanelsProps) => {
			const [activePanel, setActivePanel] = useState(0);
			const { data: panels } = props;

			return (
				<div>
					<MainTabPanel style={{ paddingLeft: 0, paddingRight: 0 }} carouselOnMobile>
						{panels
							.filter((p) => p)
							.map((panel, i: number) => {
								// Look up the icon as needed. If we don't find the icon by name, then
								// just don't show an icon at all.
								const icon = (panel?.icon && Icons[panel.icon as keyof typeof Icons]) || undefined;
								return (
									<MainTabItem
										key={panel?.contentful_id || ''}
										text={panel?.tabTitle || ''}
										iconTop={icon}
										style={{ marginRight: '15px' }}
										onClick={() => setActivePanel(i)}
										isActive={activePanel === i}
									/>
								);
							})}
					</MainTabPanel>
					<div className={styles.industryStatisticsPanelContent}>
						{getRichText(panels[activePanel]?.content, defaultRichTextOptions)}
					</div>
				</div>
			);
		};

		return addLayout(
			<div>
				<Helmet>
					<title>{curatedTitle(title)}</title>
				</Helmet>
				<MainImage
					backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
					header={title}
					paragraph={subtitle}
				/>
				<SectionContainer isGrey style={{ paddingTop: '70px' }}>
					<SectionContainerTitle
						text={overviewHeading || defaultIndustryPageSections.overview}
						id={LinkHelper.parseInternalLink(
							overviewHeading || defaultIndustryPageSections.overview
						)}
					/>
					{overviewContent && (
						<div className={styles.overviewContentContainer}>
							{getRichText(overviewContent, defaultRichTextOptions)}
						</div>
					)}
					<CollapsiblePanelCollection
						panelsCollection={overviewCollapsiblePanels}
						parentId={LinkHelper.parseInternalLink(
							overviewHeading || defaultIndustryPageSections.overview
						)}
					/>
				</SectionContainer>
				<SectionContainer>
					<SectionContainerTitle
						text={industryStatisticsHeading || defaultIndustryPageSections.industryStatistics}
						id={LinkHelper.parseInternalLink(
							industryStatisticsHeading || defaultIndustryPageSections.industryStatistics
						)}
					/>
					{industryStatisticsPanels && <IndustryStatisticsPanels data={industryStatisticsPanels} />}
					{industryStatisticsMoreInfoLinks && (
						<div>
							<h4 className={styles.lookingForMoreInfo}>Looking for more information?</h4>
							<SectionContainerGrid columns="2" className={styles.infoLinkGrid}>
								{industryStatisticsMoreInfoLinks
									.filter((item) => item)
									.map((item, i: number) => {
										return (
											<LinkWithDescriptionTemplate
												key={`${item?.contentful_id}_${i}`}
												data={item}
											/>
										);
									})}
							</SectionContainerGrid>
						</div>
					)}
				</SectionContainer>
				{videoResources && (
					<SectionContainer isGrey>
						<SectionContainerTitle
							text={videosHeading || defaultIndustryPageSections.videos}
							withSeparator
							id={LinkHelper.parseInternalLink(videosHeading || defaultIndustryPageSections.videos)}
						/>
						<MultiCarousel
							itemsShown={{
								largeScreen: 2,
								mediumScreen: 2,
								smallScreen: 1,
							}}
						>
							{getVideoThumbnails(videoResources)}
						</MultiCarousel>
					</SectionContainer>
				)}
				{industryResourcesLinks && (
					<SectionContainer>
						<SectionContainerTitle
							text={industryResourcesHeading || defaultIndustryPageSections.industryResources}
							id={LinkHelper.parseInternalLink(
								industryResourcesHeading || defaultIndustryPageSections.industryResources
							)}
						/>
						<SectionContainerGrid columns="2" className={styles.infoLinkGrid}>
							{industryResourcesLinks
								.filter((item) => item)
								.map((item, i: number) => {
									return (
										<LinkWithDescriptionTemplate key={`${item?.contentful_id}_${i}`} data={item} />
									);
								})}
						</SectionContainerGrid>
					</SectionContainer>
				)}
				{industryLinksCollapsiblePanels && (
					<SectionContainer isGrey>
						<SectionContainerTitle
							text={industryLinksHeading || defaultIndustryPageSections.industryLinks}
							id={LinkHelper.parseInternalLink(
								industryLinksHeading || defaultIndustryPageSections.industryLinks
							)}
						/>
						<CollapsiblePanelCollection
							parentId={LinkHelper.parseInternalLink(
								industryLinksHeading || defaultIndustryPageSections.industryLinks
							)}
							panelsCollection={industryLinksCollapsiblePanels}
						/>
					</SectionContainer>
				)}
			</div>
		);
	}
}
