import styles from './styles.module.css';
import classNames from 'classnames';
import React, { ComponentType, CSSProperties, ReactNode } from 'react';
import { MultiCarousel } from '../multiCarousel';
import { LinkHelper } from 'src/utils/link-helper';

interface PropsItem {
	text?: string | null;
	id?: string;
	onClick?: () => any;
	isActive?: boolean;
	iconTop?: ComponentType<any>;
	textStyle?: CSSProperties;
	style?: CSSProperties;
}

export const MainTabItem = (props: PropsItem) => {
	const { text, id, onClick, isActive, iconTop: IconElement, textStyle, style } = props;
	const wrapperClass = classNames(
		styles.wrapperItem,
		{ [styles.itemActive]: isActive },
		{ [styles.wrapperItemNoIcon]: !IconElement },
		{ [styles.wrapperItemIcon]: IconElement }
	);
	const textClass = classNames(
		{
			[styles.itemTextNoIcon]: !IconElement,
		},
		{ [styles.itemTextIcon]: IconElement }
	);

	return (
		<div onClick={onClick} className={wrapperClass} style={style}>
			{IconElement && (
				<div className={styles.iconContainer}>
					<IconElement
						imgCss={{ width: 'unset', maxWidth: '100%' }}
						containerCss={{ marginTop: 'auto', marginBottom: 'auto' }}
					/>
				</div>
			)}
			<h4 className={textClass} style={textStyle} id={id}>
				{text}
			</h4>
		</div>
	);
};

interface Props {
	tabsPosition?: 'left' | 'center' | 'right';
	carouselOnMobile?: boolean;
	children: ReactNode;
	style?: CSSProperties;
}

export const MainTabPanel = (props: Props) => {
	const { tabsPosition, children, style, carouselOnMobile } = props;
	const wrapperClassDesktop = classNames(styles.wrapper, {
		[styles.wrapperResponsiveWide]: carouselOnMobile,
	});

	const getJustifyContent = () => {
		switch (tabsPosition) {
			case 'left':
				return 'flex-start';
			case 'right':
				return 'flex-end';
			default:
				return 'space-between';
		}
	};

	return (
		<>
			<div
				className={wrapperClassDesktop}
				style={{ justifyContent: getJustifyContent(), ...style }}
			>
				{children}
			</div>
			{carouselOnMobile && (
				<div className={styles.wrapperResponsiveNarrow}>
					<MultiCarousel>{children}</MultiCarousel>
				</div>
			)}
		</>
	);
};
